import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Event from '../components/Event';
import BannerCarousel from '../components/BannerCarousel';
import LoadingSpinner from '../components/LoadingSpinner';
import { assets, environment } from '../data';
import SlideShow from '../components/SlideShow';
import Oneticketimg from '../assets/img/onepaylogo.png';
import iso from '../assets/img/iso.jpeg';
import { set, useForm } from 'react-hook-form';

// import { useMeta } from '../MetaContext';

export default function ContactUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribeLoading, setIsSubscribeLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullname: '',
      email: '',
      phone: '',
      email_msg: '',
    },
    mode: 'onChange',
  });
  function checkMsg() {
    const message = getValues('email_msg');
    const pattern = /\p{Extended_Pictographic}/u;

    if (pattern.test(message)) {
      return "Can't use emojis";
    } else {
      return true;
    }
  }
  function onSubmit(data) {
    let body = {
      name: data.fullname,
      email: data.email,
      phone: '(OneTicket) ' + data.phone,
      message: data.email_msg,
    };

    console.log(body);

    axios
      .post(`${environment.BASE_URL2}/merchant/contact_form/`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // console.log(res);
        const data = res?.data;
        console.log(data);

        if (data?.status === 100 && data.message === 'success') {
          toast.success(
            'Success! Your request is successfully submitted. Our team will get back to you soon',
            {
              id: 'sendMail',
            },
          );
          //   setPopupVisible(false);
        } else {
          console.error(data.message || 'Submit failed!');
          toast.error('Oh! Something went wrong!', { id: 'sendMail' });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong!', { id: 'buyTickets' });
      })
      .finally(() => {});
  }
  useEffect(() => {
    let subdomain = window.location.hostname.split('.')[0];
    if (subdomain === 'oneticket' || subdomain === 'localhost') {
      subdomain = '';
    }
    //setSubDomain(subdomain);
    console.log('Subdomain:', subdomain);
    if (subdomain == 'dev-organization') {
      getData('');
    } else {
      getData(subdomain);
    }
  }, []);
  function getData(subdomain) {
    setIsLoading(true);

    axios
      .get(
        `${environment.BASE_URL2}/events/event-all/?limit=10000&page=1&sub_domain=${subdomain}`,
      )
      .then((res) => {
        // console.log(res);
        const data = res?.data;
        console.log(data.data);
        if (data?.status === 223) {
          navigate('/notfound');
        }
        if (data?.status === 100) {
          setEvents(data.data.data);
        } else {
          console.error(data.message || 'Getting events failed!');
          toast.error('Getting events failed!', { id: 'getEvents' });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong', { id: 'getEvents' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <div className="container" style={{ marginTop: '-64px' }}>
        <div className="events">
          <div className="content">
            <div className="row align-items-center">
              <div className="col-md-8 col-12" style={{ padding: '0' }}>
                <div className="d-flex flex-column h-100 justify-content-center">
                  <p className="our_story mb-0">Contact us</p>
                  <h4 className="our_story_text">
                    Leading online ticketing platform.
                  </h4>
                </div>
              </div>
              <div
                className="col-md-4 col-12 oneticket_res"
                style={{ padding: '0' }}
              >
                <div
                  className="d-flex h-100 justify-content-md-center justify-content-start align-items-center"
                  style={{ float: 'right' }}
                >
                  <img
                    className="oneticket_logo"
                    src={Oneticketimg}
                    alt="OneTicket Logo"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="about_line"></div>

          <div className="form_con">
            <div className="row">
              <div className="col-md-5 contactus-form__sec">
                <h2 className="contactus-detail__title">
                  Thank you for your interest in <br /> OneTicket
                </h2>
                <p className="contactus-detail__para">
                  To perceive a smart society by providing high quality customer
                  experience. If your customer wants to pay with a credit/debit
                  card, transfer money online or even use an E-wallet, your
                  company will be able to facilitate it with the help of Onepay
                </p>
                <div className="contactus-detail__logo">
                  <img src={iso} alt="iso-logo" />
                </div>
              </div>
              <div className="col-md-7">
                <h2 className="contactus-detail-form__title">Say hello</h2>
                <p className="say_p">
                  Lorem Ipsum is simply dummy text of the printing .
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mt-5 pr-3">
                    <div className="d-flex flex-column flex-sm-row align-items-start">
                      <div
                        className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3"
                        style={{ marginRight: '0.4rem' }}
                      >
                        <label className="label_name">
                          Full Name <span className="required-sign">*</span>
                        </label>

                        <input
                          type="text"
                          className={`form-control contactus-form-input ${
                            errors.fullname ? 'on-error' : ''
                          }`}
                          placeholder="Write your first name"
                          {...register('fullname', {
                            required: {
                              value: true,
                              message: 'Write your full name',
                            },
                          })}
                        />

                        {errors.fullname ? (
                          <p className="error-text">
                            {errors.fullname.message}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-12 col-sm-6 pe-0 pe-sm-2 mb-3">
                        <label className="label_name">
                          Phone Number <span className="required-sign">*</span>
                        </label>

                        <input
                          type="text"
                          className={`form-control contactus-form-input ${
                            errors.phone ? 'on-error' : ''
                          }`}
                          placeholder="Write your phone number"
                          {...register('phone', {
                            required: {
                              value: true,
                              message: 'Write your full name',
                            },
                          })}
                        />

                        {errors.phone ? (
                          <p className="error-text">{errors.phone.message}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      <label className="label_name">
                        Email address <span className="required-sign">*</span>
                      </label>

                      <input
                        type="text"
                        className={`form-control contactus-form-input ${
                          errors.email ? 'on-error' : ''
                        }`}
                        placeholder="Write your email adress"
                        {...register('email', {
                          required: {
                            value: true,
                            message: 'Write your full name',
                          },
                        })}
                      />

                      {errors.email ? (
                        <p className="error-text">{errors.email.message}</p>
                      ) : null}
                    </div>
                    <div className="col-12 mb-3">
                      <label className="label_name">
                        Message <span className="required-sign">*</span>
                      </label>

                      <textarea
                        className={`form-control contactus-form-textarea ${
                          errors.email_msg ? 'on-error' : ''
                        }`}
                        placeholder="Write your message"
                        {...register('email_msg', {
                          required: {
                            value: true,
                            message: 'Write your message',
                          },
                          validate: { imoji: checkMsg },
                        })}
                        rows={7}
                      />

                      {errors.email_msg ? (
                        <p className="error-text">{errors.email_msg.message}</p>
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-4 mb-4">
                      <button
                        className="pay-btn btn primary-btn2"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="about_line"></div>

          <div className="events-2" style={{ marginTop: '4rem' }}>
            <div className="events-2-1 d-flex align-items-center justify-content-between mb-3">
              <h6>Events</h6>
            </div>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {events.length > 0 ? (
                  <div className="list">
                    {events.map((e, i) => (
                      <Event key={i} event={e} />
                    ))}
                  </div>
                ) : (
                  <p className="my-5">No available events!</p>
                )}
              </>
            )}
          </div>

          <div className="events-1 row mt-5">
            <SlideShow />

            <div className="events-1-2 d-none d-lg-block col-lg-6"></div>
          </div>
        </div>
      </div>
    </>
  );
}
