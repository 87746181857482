import React from 'react';

import { assets } from '../data';
import oneticketLogo2 from '../assets/img/onepaylogo.png';
import facebook from '../assets/img/facebook.png';
import twitter from '../assets/img/twitter.png';
import linkedin from '../assets/img/linkedin.png';
import insta from '../assets/img/instagram.png';
import whatsappicon from '../assets/svg/whatsappsvg.svg';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container footer__top">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 footer__top-section">
            <img
              src={oneticketLogo2}
              alt="onepay-logo"
              className="footer__logo"
            />
            <p className="footer__paragraph">
              OneTicket, Sri Lanka's trusted ticket marketplace, offers a
              secure, ISO 27001-certified platform for all event types, backed
              by OnePay payment gateway. Managed by Spemai(PVT) Ltd, regulated
              by the Central Bank of Sri Lanka.
            </p>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12 footer__top-section">
            <h3 className="footer__links_heading">Links</h3>
            <div>
              <a href="/about_us" className="footer_a_tag" target="_parent">
                About Us
              </a>
            </div>
            <div>
              <a href="/contact_us" className="footer_a_tag" target="_parent">
                Contact Us
              </a>
            </div>
            {/* <div>
              <a
                href="https://privacy-onepay.spemai.com/"
                download
                className="footer_a_tag"
                target="_blank"
                rel="noreferrer"
              >
                Privancy Policy
              </a>
            </div> */}

            {/* <div>
              <a
               
                className="footer_a_tag"
                target="_blank"
                rel="noreferrer"
              >
                Case Studies
              </a>
            </div>
            <div>
              <a
              
                className="footer_a_tag"
                target="_blank"
                rel="noreferrer"
              >
                How it works
              </a>
            </div>
            <div>
              <a
               
                className="footer_a_tag"
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </div>
            <div>
              <a
               
                className="footer_a_tag"
                target="_blank"
                rel="noreferrer"
              >
                Careers
              </a>
            </div>
            <div>
              <a
               
                className="footer_a_tag"
                target="_blank"
                rel="noreferrer"
              >
                Areas We Serve
              </a>
            </div> */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 footer__top-section">
            <h3 className="footer__links_heading">Contact us</h3>
            <p className="footer__paragraph">
              3rd Floor, 292, Richmond House, <br /> Gamsabha Junction, High
              Level Road,
              <br />
              Nugegoda, Sri Lanka.
            </p>
            <a href="tel:+94 11 7 021 540" className="footer__paragraph">
              +94 76 052 3025
            </a>{' '}
            <br />
            <a href="tel:+94 11 702 1540" className="footer__paragraph">
              +94 11 702 1540
            </a>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12 footer__top-section">
            <div className="footer-logo-section">
              <ul className="footer-socialmedia">
                <li className="footer-socialmedia__item">
                  <a
                    href="https://www.facebook.com/OneTicket.Onepay.lk"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-socialmedia__item-link"
                  >
                    <img src={facebook} alt="socialmedia-logo" />
                  </a>
                </li>
                <li className="footer-socialmedia__item">
                  <a
                    href="https://www.instagram.com/oneticket.lk?igsh=MTFjYWUxeTA4cWdxZg=="
                    target="_blank"
                    rel="noreferrer"
                    className="footer-socialmedia__item-link"
                  >
                    <img src={insta} alt="socialmedia-logo" />
                  </a>
                </li>
                <li className="footer-socialmedia__item">
                  <a
                    href="https://whatsapp.com/channel/0029VaZFgan7YSczRoYSF90y"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-socialmedia__item-link"
                  >
                    <img
                      style={{ width: '15px' }}
                      src={whatsappicon}
                      alt="socialmedia-logo"
                    />
                  </a>
                </li>
                <li className="footer-socialmedia__item">
                  <a
                    href="https://www.linkedin.com/company/oneticket-lk/"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-socialmedia__item-link"
                  >
                    <img src={linkedin} alt="socialmedia-logo" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright_text">
        <div className="footer__horizontal_line"></div>
        <p className="footer__copyrigh_paragraph">
          © 2024 Copyright by OneTicket. All rights reserved.
        </p>
      </div>
    </footer>

    // <div className="footer">
    //   <div className="footer-1 mb-4 mb-md-5">
    //     <div className="container">
    //       <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
    //         <div className="d-flex align-items-center mb-3 mb-md-0">
    //           <a
    //             href="https://www.facebook.com/onepayforbusiness"
    //             target="_blank"
    //             rel="noreferrer"
    //             className="social-link d-flex align-items-center justify-content-center me-3"
    //           >
    //             <img
    //               src={assets.facebook_icon}
    //               alt="facebook icon"
    //               loading="lazy"
    //             />
    //           </a>

    //           <a
    //             href="https://www.instagram.com/onepayforbusiness/"
    //             target="_blank"
    //             rel="noreferrer"
    //             className="social-link d-flex align-items-center justify-content-center me-3"
    //           >
    //             <img
    //               src={assets.instagram_icon}
    //               alt="instagram icon"
    //               loading="lazy"
    //             />
    //           </a>

    //           <a
    //             href="https://twitter.com/onepaylk"
    //             target="_blank"
    //             rel="noreferrer"
    //             className="social-link d-flex align-items-center justify-content-center me-3"
    //           >
    //             <img
    //               src={assets.twitter_icon}
    //               alt="twitter icon"
    //               loading="lazy"
    //             />
    //           </a>

    //           <a
    //             href="https://www.linkedin.com/company/68035901/admin/"
    //             target="_blank"
    //             rel="noreferrer"
    //             className="social-link d-flex align-items-center justify-content-center"
    //           >
    //             <img
    //               src={assets.linkedIn_icon}
    //               alt="linkedin icon"
    //               loading="lazy"
    //             />
    //           </a>
    //         </div>

    //         <div className="d-flex align-items-center info">
    //           <p className="me-4">+94 (11) 702 1540</p>
    //           <p>info@onepay.lk</p>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="footer-2">
    //     <div className="container">
    //       <div className="d-flex align-items-center justify-content-center py-4 text-center">
    //         <p>© 2023 Copyright by OneTicket. All rights reserved.</p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
